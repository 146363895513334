angular.module('angus.controllers').controller('maintenanceUpdateController', [
    '$scope', '$rootScope', '_', 'inventoryManagementService', 'maintenanceUpdateService', 'subscriberService', 'constantsService',
    function($scope, $rootScope,  _, inventoryManagementService, maintenanceUpdateService, subscriberService, constantsService) {

        'use strict';  
  
        $scope.csv = {};
        $scope.alerts = [];
        $scope.validData = true;
        $scope.errorCount = 0;
        $scope.statuses = [];
        $scope.monitorInventoryStatusKey = '';
        $scope.isStatusLoading = false; 
        $scope.numberOfTanksUpdated = -1;
        $scope.areSubscriberDropdownsLoading = false;
        $scope.subscribers = [];
        $scope.subscriber = null;
        $scope.numberOfTanksUpdatedWithErrorsCount = 0;
        $scope.csvContentCount = 0;
        $scope.tanksUpdated = [];
        $scope.selectedSubscriberKey = '';

        $scope.subscriberChange = function() {   
            $scope.subscriber = _.find($scope.subscribers, function(subscriber){
                return subscriber.abosKey == $scope.selectedSubscriberKey;
            });
        }

        $scope.updateStatusHandler = function(csv, flag) {  
            var serialNumbers = []; 
            $scope.errorCount = 0;
            _.forEach(csv.content, function(item) {    
                serialNumbers.push(item)
            });     
            const uniqueSerialNumbers = [...new Set(serialNumbers)];   
            var data = {
                subscriberKey: $scope.selectedSubscriberKey,
                serialNumbers: uniqueSerialNumbers, 
                monitorInventoryStatusKey: $scope.monitorInventoryStatusKey,
                updateUser: $rootScope.user.username
            }
            maintenanceUpdateService.postMaintenanceUpdate(data) 
            .then(function(result) {   
                $scope.numberOfTanksUpdated = -1;
                $scope.csvContentCount = csv.content ? csv.content.length : 0;
                if (result && result.status == 200) { 
                    const returnedData = result.data[0];
                    $scope.tanksUpdated = returnedData;
                    _.forEach(returnedData, function(item) {
                        $scope.numberOfTanksUpdatedWithErrorsCount += item.ValidationMessage ? 1 : 0;
                    });
                    $scope.numberOfTanksUpdated = $scope.csvContentCount - $scope.numberOfTanksUpdatedWithErrorsCount;
                }
                else {
                    $scope.alerts.push({type: 'danger', msg: 'Failed to update the Monitor data!'}); 
                }
            });  
        } 
        
        $scope.fileOnChange = function() {
            $scope.csv = null;
            $scope.errorCount = 0; 
            $scope.numberOfTanksUpdated = -1;
            $scope.tanksUpdated = [];
        }
        
        $scope.checkData = function(data) {  
            var plusRegex = /\+/;
            var result = plusRegex.test(data);   
            if (result == true) {
                $scope.validData = false; 
                if ($scope.errorCount === 0) {
                    $scope.alerts.push({type: 'danger', msg: 'Found invalid data, please convert serial number column to "Number Format" to fix this issue'}); 
                    $scope.errorCount += 1;
                }
                return data + ' //Not Valid'; 
            }
            else {
                return data;
            }
        }

        $scope.cancelHandler = function(form) {  
            if (form) {
                form.$setPristine();
                form.$setUntouched();
            } 

            $scope.csv = null;
            $scope.errorCount = 0;
            angular.element("input[type='file']").val(null);
            $scope.numberOfTanksUpdated = -1;
            $scope.tanksUpdated = [];
        } 
        
        $scope.closeAlert = function (index) {
            $scope.alerts.splice(index, 1);
        }   

        var getAmsSubscribers = function() {
            $scope.areSubscriberDropdownsLoading = true;
            subscriberService.getAmsSubscribers()
            .then(function(subscribers){
                $scope.subscribers = _.filter(subscribers, function(subscriber){
                    return subscriber.isPaygo && subscriber.registrationStatusKey === constantsService.registrationStatus.live;
                }); 
                $scope.subscribers.push({id:0, name:'No Subscriber'});
                $scope.areSubscriberDropdownsLoading = false;
            }); 
        } 

        var getStatuses = function() {   
            $scope.isStatusLoading = true;
            inventoryManagementService.getMonitorInventoryStatus() 
            .then(function(result) { 
                var allowedStatuses = result.data;    
                $scope.statuses = _.filter(allowedStatuses, function(status){
                    return status.monitorInventoryStatusName == 'Decommissioned' || status.monitorInventoryStatusName == 'Under Repair';
                });  
                $scope.isStatusLoading = false; 
            }); 
        }   

        var init = function() {   
            Promise.all([ 
                getAmsSubscribers(),
                getStatuses() 
            ])
            .then(function(results) {
            });
        } 

        init(); 

    }
]);