angular.module('angus.controllers')
    .controller('tmsMonitorAdminContainerCtrl', ['$scope', 'actionViewTemplates', '_', 'windowsService',
    function($scope, actionViewTemplates, _, windowsService) {
        'use strict';

        windowsService.setCallingScopeCallBack($scope.windowId, function(newCallingScope){
            const newModel = newCallingScope.model;

            if (newModel.isAdmin) {
                $scope.isAdmin = true;
            }

            if (newModel.monitor) {
                $scope.openMonitor(newModel.monitor);
            }
            else {
                $scope.activeItem = $scope.monitorsItem;
            }
          });


        $scope.sideNavTemplate = actionViewTemplates.sideNav;

        $scope.monitorsItem = {
            name: 'Monitors',
            icon: 'map',
            templateUrl: actionViewTemplates.tmsMonitorAdminMonitors,
            active: true,
            isAdmin : true,
        };

        function init() {
            $scope.lastSearchTerm = null;
            $scope.pageSize       = 20;
            $scope.items          = [$scope.monitorsItem];
            if ($scope.monitor)
                $scope.openMonitor($scope.monitor);
            else
                $scope.activeItem = $scope.monitorsItem;
        }

        $scope.switchActiveItem = function(item) {
            if(item.active) $scope.activeItem = item;
        };

        $scope.removeMonitor = function(item) {
            var index = _($scope.items).findIndex(item);
            _.remove($scope.items, item);
            if (index <= $scope.items.length - 1) $scope.activeItem = $scope.items[index];
            else $scope.activeItem = $scope.items[index - 1];
        };


        function openItem(serialNumber){
            return _.find($scope.items, function(i) {
                return i.monitor && i.monitor.serialNumber == serialNumber;
            });
        }

        function newItem(monitor){
            var item = {
                icon        : 'square',
                templateUrl : actionViewTemplates.tmsMonitorAdminMonitor,
                active      : true,
                monitor     : monitor,
                name        : monitor.serialNumber
            };

            $scope.items.push(item);

            return item;
        }

        function isMonitorOpen(serialNumber) {
            return !!openItem(serialNumber);
        }

        $scope.openMonitor = function(monitor, searchTerm) {
            $scope.lastSearchTerm = searchTerm;
            $scope.activeItem     = isMonitorOpen(monitor.serialNumber) ? openItem(monitor.serialNumber) : newItem(monitor);
        };


        init();

    }])
    .controller('tmsMonitorAdminMonitorsController', [
        '$scope', '$rootScope', '$q', 'fluentRest', 'Paging', 'promiseMonitor', 'csvService', 'modalService','actionViewTemplates',
        function($scope, $rootScope, $q, fluentRest, Paging,  PromiseMonitor, csvService, modalService, actionViewTemplates) {
            'use strict';

            $scope.load = true;

            $scope.mappedFilterList = [{value: 'Y', name: 'Yes'}, {value: 'N', name: 'No'}, {value: 'O', name: 'Only'}];

            $scope.clearSearch = function() {
                delete $scope.monitorsItem.searchTerm;
            };

            var getParams = function() {
              return {
                subscriberABOSKey: $scope.monitorsItem.selectedSubscriber ? $scope.monitorsItem.selectedSubscriber.abosKey : null,
                mappedFilter: $scope.monitorsItem.selectedMappedFilter.value,
                searchTerm: $scope.monitorsItem.searchTerm,
                sortBy: $scope.sortBy,
                ascending: $scope.ascending
              };
            };

            $scope.getMonitors = function(pageNumber) {
                var params = getParams();

                var url = fluentRest.api().monitors().toString();

                var promise = $scope.monitorsItem.paging.getPage(url, pageNumber, params)
                    .then(function(monitors) {
                        $scope.monitorsItem.monitors = monitors.items;
                    })
                    .catch(function(){
                        $scope.monitorsItem.monitors = [];
                    });

                $scope.monitorsMonitor = new PromiseMonitor(promise);

                return promise;
            };

            $scope.getSubscriberList = function() {
                return fluentRest
                    .api()
                    .subscribers()
                    .get()
                    .then(function(subscribers){
                        $scope.subscribersList = subscribers;
                        $scope.subscribersList.unshift({abosKey : -2, name : '-NO SUBSCRIBER-'});
                        $scope.subscribersList.unshift({abosKey : -1, name : '-ALL SUBSCRIBERS-'});
                    });
            };


            $scope.downloadInProgress = function(){
                return csvService.downloadInProgress();
            };


            $scope.export = function() {
                var params = getParams();

                var url = fluentRest.api().monitors().toString();

                csvService.getCsv(url, params, 'TMS Monitors');
            };

            $scope.sort = function(sortBy) {
                if ($scope.sortBy == sortBy) $scope.ascending = !$scope.ascending;
                else {
                    $scope.sortBy = sortBy;
                    $scope.ascending = true;
                }
                $scope.getMonitors();
            };

            function init() {
                $scope.promiseMonitor = new PromiseMonitor();

                if(!$scope.monitorsItem.initialized) {
                    $scope.monitorsItem.initialized = true;

                    $scope.monitorsItem.paging = new Paging($scope.monitorsItem.pageSize || 25);
                    $scope.sortBy = 'serialNumber';
                    $scope.ascending = true;
                    $scope.monitorsItem.selectedMappedFilter = $scope.mappedFilterList[0];

                    var promise =  $q.all([
                        $scope.getSubscriberList(),
                        // $scope.getMonitors()
                    ]);
                }

                $scope.getSubscriberList();

                $scope.promiseMonitor.monitor(promise);
            }

            init();
        }
    ])
    .controller('tmsMonitorAdminEditMonitorController', [
        '$rootScope', '$scope', 'fluentRest', 'constantsService', 'hierarchyService', 'promiseMonitor',
        function($rootScope,$scope, fluentRest, constantsService, hierarchyService,  PromiseMonitor) { 
            'use strict';  

            $scope.submitCustomer = function(event, monitor) {
                $scope.message = '';
                event.preventDefault(); 

                var customer = { 
                    CustomerTankID: monitor.idDisplay,
                    CustomerFirstName: monitor.customer.firstName,
                    CustomerLastName: monitor.customer.lastName,
                    CustomerEmailAddress: monitor.customer.email,
                    CustomerAddressLine1: monitor.address.addressLine1,
                    CustomerAddressLine2: monitor.address.addressLine2,
                    CustomerID: monitor.customer.idDisplay,
                    CustomerPhoneNumber: monitor.customer.phoneNumber,
                    CustomerPostalCode: monitor.address.postalCode,
                    CustomerState: monitor.address.state,
                    CustomerCity: monitor.address.city,
                    ContainerKey: monitor.tankId 
                };
                var params = { 
                    divisionKey: monitor.divisionKey,  
                    records: [customer],
                    insertUser: $rootScope.user.role
                }
                fluentRest
                    .api()
                    .subscribers(monitor.subscriberId)
                    .customerAccounts()
                    .import()
                    .update()
                    .post(params)
                .then(function (results) {
                  if (results) {
                    $scope.message = results.result;
                    $rootScope.$broadcast('monitorUpdate', true);
                  }
                  else {
                    $scope.message = 'Fail to update customer!!';
                  }
                });
              }  
    }])
    .controller('tmsMonitorAdminMonitorMappingController', [
        '$scope', 'fluentRest', 'modalService', 'actionViewTemplates', 'moment', '$q', '_', 'promiseMonitor', 'hierarchyService',
        function($scope, fluentRest, modalService, actionViewTemplates, moment, $q, _, PromiseMonitor, hierarchyService) {

            'use strict';
            var existingMapping = $scope.existingMapping;


            function getSubscribers(){
                if($scope.paygoProducts) return $q.when();

                return fluentRest
                        .api()
                        .subscribers()
                            .get()
                            .then(function(subscribers){
                                $scope.subscribers = subscribers;
                            });
            }

            $scope.searchCustomers = function(searchTerm){
                if(!$scope.model.selectedSubscriber.id || !$scope.model.selectedSubscriber.id ||  ($scope.mappingSearchMonitor && $scope.mappingSearchMonitor.isPending())) return $q.when();

                delete $scope.customers;

                var params = {
                    pageNumber  : 1,
                    pageSize    : 5,
                    sortBy      :'name',
                    ascending   : true,
                    searchTerm  : searchTerm,
                    dealerId    : $scope.model.selectedDivision
                };

                var promise = fluentRest
                        .api()
                        .subscribers($scope.model.selectedSubscriber.id)
                        .customerAccounts()
                            .get(params)
                            .then(function(customers){
                                $scope.customers = customers;
                                return $scope.customers;
                            });

                $scope.mappingSearchMonitor = new PromiseMonitor(promise);

                return promise;
            };

            $scope.clearCustomer = function(){
                delete $scope.customers;
                delete $scope.model.selectedCustomer;
                delete $scope.model.containerId;
            };

            $scope.clearSubscriber = function(){
                $scope.clearCustomer();
                delete $scope.model.selectedSubscriber;
            };

            $scope.selectCustomer = function(customer){
                $scope.model.selectedCustomer = customer;
                $scope.model.containerId = $scope.model.selectedCustomer.containers[0].id;
            };

            $scope.save = function(){
                var result = {
                };

                if($scope.model.selectedSubscriber){
                    result.subscriberABOSKey = $scope.model.selectedSubscriber.abosKey;
                    result.subscriberName    = $scope.model.selectedSubscriber.name;
                    if($scope.model.containerId){
                        result.containerId = $scope.model.containerId;
                    }
                }

                this.close(result);
            };

            $scope.setDivisions = function(subscriber) {
                if(!subscriber) {
                  return $scope.model.divisions = [];
                }
                var promise = hierarchyService
                  .getDealerHierarchy(subscriber.id)
                  .then(function(divisions) {
                    return $scope.model.divisions = divisions;
                  });

                $scope.divisionMonitor.monitor(promise);
                return promise;
            };

            $scope.onSubscriberChange = function() {
              $scope.clearCustomer();

              $scope.model.divisions = [];
              $scope.setDivisions($scope.model.selectedSubscriber);
            };

            function init(){
                $scope.model = {};
                $scope.divisionMonitor = new PromiseMonitor();

                var promise =  getSubscribers()
                    .then(function(){
                        if(existingMapping){

                            $scope.model.selectedSubscriber = _.find($scope.subscribers, function(subscriber){
                                return subscriber.abosKey == existingMapping.subscriberABOSKey;
                            });

                            return $scope.model.selectedSubscriber;
                        }
                    }).then($scope.setDivisions);

                $scope.mappingMonitor = new PromiseMonitor(promise);

            }

            init();
        }])
    .controller('tmsMonitorAdminMonitorController', [
        '$scope', 'fluentRest', 'modalService', 'actionViewTemplates', 'moment', '$http', '_', 'alertsService',
        function($scope, fluentRest, modalService, actionViewTemplates, moment, $http, _, AlertsService) {

            'use strict';

            var serialNumber = $scope.activeItem.monitor.serialNumber;
            
            $scope.activeItem.alerts = new AlertsService(); 

            function getLinkedTank(){
                  return fluentRest
                    .api()
                    .monitors(serialNumber)
                    .linkedTank()
                    .get()
                    .then(function(result){
                        $scope.activeItem.monitor.tankInformation = result || {};
                        getMonitorFromCommon();
                    });
            }
           
            function getMonitorFromCommon() {
                return $http({
                    url: 'api/monitors/{0}/monitor'.format(serialNumber),
                    method: 'GET'
                }).then(function (res) {
                    var data = res.data;
                    if (data) {
                        $scope.activeItem.monitor.tankInformation.monitorTypeKey = data.monitorTypeKey;
                        $scope.activeItem.monitor.tankInformation.monitorTypeName = data.monitorTypeName;
                    }
                    return data;
                })
            }

            if(serialNumber){
                return getLinkedTank()
                    .then(function(){

                        $scope.activeItem.monitor.openCustomerFromMonitor = function(){
                            var scope = {
                                widgetName: 'TMS Admin - Customers',
                                widgetIcon: 'map',
                                widget: {
                                    instance: {
                                        id: 'paygoTMSAdmin'
                                    }
                                },
                                model : {
                                    monitor : $scope.activeItem.monitor.tankInformation
                                }
                            };

                            return modalService.openActionView(actionViewTemplates.paygoTMSAdminDefault, scope, 'paygoTMSAdmin');
                        };

                        $scope.activeItem.monitor.editMapping = function(){
                            var scope = {
                                existingMapping : {
                                    subscriberABOSKey   : $scope.activeItem.monitor.subscriberABOSKey,
                                    accountNumber       : $scope.activeItem.monitor.tankInformation && $scope.activeItem.monitor.tankInformation.customer ? $scope.activeItem.monitor.tankInformation.customer.idDisplay : undefined,
                                    containerId         : $scope.activeItem.monitor.containerId
                                }
                            };
                            return modalService
                                .openTopBarModal(actionViewTemplates.tmsMonitorEditMonitorMapping, scope)
                                .then(function(obj){
                                    if(!obj) return;

                                     var installedAtDealer = 3; 
                                     var data = {
                                        monitors : [{
                                            serialNumber            : $scope.activeItem.monitor.serialNumber,
                                            subscriberABOSKey       : obj.subscriberABOSKey,
                                            containerId             : obj.containerId,
                                            monitorTypeKey          : $scope.activeItem.monitor.tankInformation.monitorTypeKey,
                                            monitorInventoryStatusKey: installedAtDealer
                                        }]
                                    };

                                     return fluentRest
                                        .api()
                                        .monitors()
                                        .map()
                                            .put(data)
                                            .then(function(){
                                                $scope.activeItem.monitor.subscriberABOSKey     = obj.subscriberABOSKey;
                                                $scope.activeItem.monitor.subscriberName        = obj.subscriberName;
                                                $scope.activeItem.monitor.containerId           = obj.containerId;
                                                return getLinkedTank();

                                            })
                                            .then(function(){
                                                $scope.activeItem.alerts.addSuccess('Saved monitor mapping.');
                                            });
                                });
                        };
                    });

            }
    }]);

