angular.module('angus.controllers').controller('monitorsController', [
    '$scope', '$rootScope', 'gridService2', 'monitorsService', 'moment', 'Paging', '_', '$q',  'nodeType', 'gridState', 
    'subscriberService', 'constantsService', 'inventoryManagementService', 'promiseMonitor',
    function($scope, $rootScope, gridService2, monitorsService, moment, Paging, _, $q, nodeType, gridState, 
        subscriberService, constantsService, inventoryManagementService, promiseMonitor) {

        'use strict';  

        var nodeColDefs; 
        $scope.showFilter = true;  
        $scope.loading = false;
        $scope.statuses = [];
        $scope.subscribers = [];
        $scope.fuels = [];
        $scope.carriers = [];
        $scope.riderStartDateOpened = false;  
        $scope.actualDeliveryStartDateOpened = false;
        $scope.riderEndDateOpened = false; 
        $scope.actualDeliveryEndDateOpened = false;
        $scope.areSubscriberDropdownsLoading = false;
        $scope.areMonitorTypesLoading = false;
        $scope.searchBySerialNumber = '';
        $scope.dateOptions = {
            formatYear: 'yy',
            startingDay: 0
        }; 
        $scope.monitorTypes = [];  
        $scope.submitLoading = false;
        $scope.submitSearching = false;
        $scope.currentMonitors = [];
        $scope.monitors = [];
        $scope.alerts = []; 
        $scope.changedMonitors = [];

        var subscriberId = $scope.subscriberId = $rootScope.user.subscriberId; 
    
        var initFilter = function() {
            $scope.loading = false; 
            return {  
                sortBy                      : 'serialNumber',
                ascending                   : true,
                serialNumber                : null,
                monitorInventoryStatusKey   : null,
                subscriberKey               : null, 
                simCardCarrierKey 	        : null, 
                monitorTypeKey              : null,
                useRiderDateFlag            : 0,
                useActualDeliveryDateFlag   : 0,
                riderFromDate 	            : null, //moment().subtract(30, "days").toDate(),
                riderToDate 	            : null, //moment().subtract(1, "days").toDate(),
                actualDeliveryFromDate      : null, //moment().subtract(30, "days").toDate(),
                actualDeliveryToDate 	    : null  //moment().subtract(1, "days").toDate()
            };
        }

        $scope.filter = initFilter(); 
    
        $scope.filterChanged = function (filter) {
            $scope.filter = filter || initFilter(); 
        };  
 
        $scope.openRiderStartDate = function($event) {
            $event.preventDefault();
            $event.stopPropagation(); 

            $scope.riderStartDateOpened = true;    
            $scope.actualDeliveryStartDateOpened = false;
            $scope.riderEndDateOpened = false;  
            $scope.actualDeliveryEndDateOpened = false;
        }   

        $scope.openDeliveryStartDate = function($event) {
            $event.preventDefault();
            $event.stopPropagation();  
 
            $scope.riderStartDateOpened = false; 
            $scope.actualDeliveryStartDateOpened = true;
            $scope.riderEndDateOpened = false; 
            $scope.actualDeliveryEndDateOpened = false;
        }

        $scope.openRiderEndDate = function($event) {
            $event.preventDefault();
            $event.stopPropagation();  
 
            $scope.riderStartDateOpened = false; 
            $scope.actualDeliveryStartDateOpened = false;
            $scope.riderEndDateOpened = true; 
            $scope.actualDeliveryEndDateOpened = false;
        }  

        $scope.openDeliveryEndDate = function($event) {
            $event.preventDefault();
            $event.stopPropagation();   
            $scope.riderStartDateOpened = false; 
            $scope.actualDeliveryStartDateOpened = false;
            $scope.riderEndDateOpened = false; 
            $scope.actualDeliveryEndDateOpened = true;
        }   
 
        $scope.toggleRiderDate = function(){  
            if ($scope.filter.useRiderDateFlag) {
                $scope.filter.riderFromDate = moment().subtract(30, "days").toDate();
                $scope.filter.riderToDate = moment().subtract(1, "days").toDate();
            }
            else {
                $scope.filter.riderFromDate = null;
                $scope.filter.riderToDate = null; 
            }
        }
        
        $scope.toggleActualDelivery = function(){  
            if ($scope.filter.useActualDeliveryDateFlag) {
                $scope.filter.actualDeliveryFromDate = moment().subtract(30, "days").toDate();
                $scope.filter.actualDeliveryToDate = moment().subtract(1, "days").toDate();
            }
            else {
                $scope.filter.actualDeliveryFromDate = null;
                $scope.filter.actualDeliveryToDate = null;
            } 
        }  

        $scope.getMonitors = function(pageNumber) { 
            $scope.submitSearching = true;
			if($scope.pageMonitor.isPending()) {
                $scope.submitSearching = false;
				return; 
            }
   
            if ($scope.filter.useRiderDateFlag) {
                if ($scope.filter.riderFromDate == null) {
                    $scope.filter.riderFromDate = moment("0001-01-01").toDate();
                }
                if ($scope.filter.riderToDate == null) {
                    $scope.filter.riderToDate = moment().subtract(1, "days").toDate();
                } 
            }
            if ($scope.filter.useActualDeliveryDateFlag) {
                if ($scope.filter.actualDeliveryFromDate == null) {
                    $scope.filter.actualDeliveryFromDate = moment("0001-01-01").toDate();
                }
                if ($scope.filter.actualDeliveryToDate == null) {
                    $scope.filter.actualDeliveryToDate = moment().subtract(1, "days").toDate();
                }
            } 

            pageNumber = pageNumber || 1;
            var url = 'api/tankMonitors/monitors/searchMonitors';
            var promise = $scope.paging.getPage(url, pageNumber, $scope.filter)
            .then(function(result){
                var monitors = result.items;
                $scope.paging.totalCount = result.totalCount; 
 
                $scope.monitors = _.map(monitors, function(monitor){ 
                    monitor.actualDeliveryDate = monitor.actualDeliveryDate ? moment(monitor.actualDeliveryDate).utc().format('YYYY-MM-DD') : null;
                    monitor.riderDate = monitor.riderDate ? moment(monitor.riderDate).utc().format('YYYY-MM-DD') : null;
                    monitor.installDate = monitor.installDate ? moment(monitor.installDate).utc().format('YYYY-MM-DD') : null;
                    monitor.readingDate = monitor.readingDate ? moment(monitor.readingDate).utc().format('YYYY-MM-DD') : null;
                    //map subscriber name using subscriberKey
                    var foundSubscriberKey = monitor.subscriberKey && monitor.subscriberKey.length ? monitor.subscriberKey[0] : null;
                    if (foundSubscriberKey) {
                        let foundSubscriberName = _.find($scope.subscribers, function(subscriber) {
                            let subscriberName = null; 
                            if (foundSubscriberKey == subscriber.abosKey) { 
                                subscriberName = subscriber.name;
                            }
                            return subscriberName;
                        }); 
                        monitor.subscriberName = foundSubscriberName ? foundSubscriberName.name : null; 
                    } 
                    return monitor;
                }); 
                $scope.currentMonitors = _.cloneDeep($scope.monitors);
                $scope.grid.setRows($q.resolve($scope.monitors));  
                $scope.submitSearching = false;
                return result;
            }); 
			$scope.pageMonitor.monitor(promise);

			return promise;
        }    
        $scope.searchString = '';

        $scope.closeAlert = function (index) {
            $scope.alerts.splice(index, 1);
        }

        $scope.submitHandler = function() {    
            $scope.changedMonitors = arraysAreEqual($scope.monitors, $scope.currentMonitors);  
            if ($scope.changedMonitors.length == 0) {
                $scope.alerts.push({type: 'danger', msg: 'Nothing to submit!'}); 
            } 
            _.forEach($scope.changedMonitors, function (monitor) { 
                if (monitor) { 
                    var data = {
                        tankMonitorKey: monitor.tankMonitorKey,
                        serialNumber: monitor.serialNumber,
                        subscriberKey: monitor.subscriberKey, 
                        containerKey: monitor.containerKey,
                        productCategoryPaygoKey: monitor.productCategoryPaygoKey,
                        shippingBoxID: monitor.shippingBoxID,
                        monitorTypeKey: monitor.monitorTypeKey,
                        firmwareVersionEvaluationFlag: monitor.firmwareVersionEvaluationFlag,
                        simNumber: monitor.simNumber,
                        purchaseOrderDeliveryDetailKey: monitor.purchaseOrderDeliveryDetailKey,
                        monitorInventoryStatusKey: monitor.monitorInventoryStatusKey,
                        monitorInventoryNotes: monitor.monitorInventoryNotes, 
                        rowStatusKey: 1,
                        updateUser: $rootScope.user.username
                    } 
                    monitorsService.postMonitors(data) 
                    .then(function(result) {
                        if (result && result.status == 200) {
                            $scope.alerts.push({type: 'success', msg: 'Monitor data updated'}); 
                        }
                        else {
                            $scope.alerts.push({type: 'danger', msg: 'Failed to update the Monitor data!'}); 
                        }
                    }); 
                }
            }); 
        }  

        //Private Methods ********************************************************************   
        var arraysAreEqual = function(arr1, arr2) {
            var changes = []; 
            if (arr1.length !== arr2.length) { 
                return changes;
            } 
            for (var i = 0; i < arr1.length; i++) { 
                if (!_.isMatch(arr1[i], arr2[i])) {
                    changes.push(arr1[i]);
                }
            } 
            return changes;
        }

        var getColDefs = function() { 
            var colDefs = [];   
            var optionValues = []; 
            _.forEach($scope.statuses, function(item) {
                optionValues.push({value: item.monitorInventoryStatusKey, text: item.monitorInventoryStatusName });
            }); 
            colDefs.push(gridService2.colDef.createSelect('monitorInventoryStatusKey','Status','monitorInventoryStatusKey',{hide: function(params){}}, {}, optionValues)); 
            colDefs.push(gridService2.colDef.createText('serialNumber','Serial Number','serialNumber', { cellClass : ['center']} ));
            colDefs.push(gridService2.colDef.createText('monitorTypeName','Monitor Type','monitorTypeName')); 
            colDefs.push(gridService2.colDef.createText('simcardCarrierName','Carrier','simcardCarrierName', { cellClass : ['center']}, {width: 80} ));
            colDefs.push(gridService2.colDef.createText('shippingBoxID','Shipping Box','shippingBoxID', { cellClass : ['center']}, {width: 120} )); 
            colDefs.push(gridService2.colDef.createDate('actualDeliveryDate','Delivery Date','actualDeliveryDate', {}, {width: 120})); 
            colDefs.push(gridService2.colDef.createText('subscriberName','Subscriber','subscriberName'));
            colDefs.push(gridService2.colDef.createDate('riderDate','Rider Date','riderDate', {}, {width: 120}));  
            colDefs.push(gridService2.colDef.createDate('installDate','Install Date','installDate', {}, {width: 120})); 
            colDefs.push(gridService2.colDef.createDate('readingDate','Reading Date','readingDate', {}, {width: 120})); 
            colDefs.push(gridService2.colDef.createTextarea('monitorInventoryNotes','Notes','monitorInventoryNotes', {}, {width: 400})); 
            colDefs.push(gridService2.colDef.createDate('refurbishedDate','Refurbished Date','refurbishedDate',{hide: function(params){}}, {width: 150}));  
 
            return colDefs;
        }

        var getStatuses = function() {   
            $scope.isStatusLoading = true;
            inventoryManagementService.getMonitorInventoryStatus() 
            .then(function(result) { 
                $scope.statuses = result.data;    
                $scope.isStatusLoading = false; 
            }); 
        }   

        var getMonitorTypes = function(subscriberId) {   
            $scope.areMonitorTypesLoading = true;
            inventoryManagementService.getMonitorTypes(subscriberId) 
            .then(function(result) { 
                $scope.monitorTypes = result;    
                $scope.areMonitorTypesLoading = false; 
            }); 
        } 

        var getSimCardCarriers = function() {   
            $scope.areCarriersLoading = true; 
            inventoryManagementService.getSIMCardCarriers()
            .then(function(result) {   
                $scope.simCardCarriers = result.data;  
                $scope.areCarriersLoading = false; 
            }); 
        }  

        var getAmsSubscribers = function() {
            $scope.areSubscriberDropdownsLoading = true;
            subscriberService.getAmsSubscribers()
            .then(function(subscribers){
                $scope.subscribers = _.filter(subscribers, function(subscriber){
                    return subscriber.isPaygo && subscriber.registrationStatusKey === constantsService.registrationStatus.live;
                }); 
                $scope.areSubscriberDropdownsLoading = false;  
                getMonitorTypes($scope.subscribers[0].id);
            }); 
        }  

        var init = function() {  
			$scope.promiseMonitor = new promiseMonitor();
			$scope.pageMonitor = new promiseMonitor();

            $scope.pageNumber = 1;
			$scope.paging = new Paging(25);   
			$scope.ascending = true;

            Promise.all([
                getAmsSubscribers(),
                getSimCardCarriers(),
                getStatuses(),
                setUpGrid()
            ])
            .then(function(results) {
            });
        } 

        init(); 

        function setUpGrid() {
            nodeType
                .getDivisionColDefs(subscriberId)
                .then(function (colDefs) {
                    nodeColDefs = colDefs;
                    var gridParams = {
                        defs: getColDefs(),
                        exportOptions: { fileName: 'Monitors' }
                    };
                    return gridService2.createGrid(gridParams);
                })
                .then(function (grid) {
                    grid.setRows(Promise.resolve());
                    $scope.grid = grid;
                });
        }
    }
]);